import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import Layout from "../components/layouts/layout";
import api from "../actions/riveroapi"
import GatsbyImage from "gatsby-image";
import FormularioSalesforce from "../components/widgets/formularios/FormularioSalesforce";

export default function Adwords (props) {

  const [ dataAdword, setDataAdword ] = useState();

  let pathAdword = props.location.pathname;
  let splitAdword = pathAdword.split("/");
  console.log("Tengo lo siguiente: ", splitAdword);

  useEffect(() => {

    let slug = "ruleta-posada-del-primo-diciembre-2019";

    api.getCampaign(splitAdword[2]).then((resp) => {
      console.log("Tengo lo siguiente: ", resp);
      setDataAdword(resp.data);
    })
  }, [])

  return(
    <Layout>
        <Container className="bg-white pt-4 pb-5">
          <Row>
            <Col>
              <img src={dataAdword?.image} className="rounded" style={{ width: "100%" }} alt={dataAdword?.title} />
            </Col>
            <Col> 
              
            </Col>
          </Row>
        </Container>
    </Layout>
  )

}